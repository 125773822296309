/*!
 * Bootstrap v3.3.6 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */


// colors
$chart_colors: (
        default : #7f8c8d,
        green   : $brand-primary,
        orange  : #ff6600,
        red     : #d1365f,
);

//
// Load core variables and mixins
// --------------------------------------------------
@mixin transition($property) {
  transition: $property cubic-bezier(0,.79,.48,1) 0.3s;
}

//
// General
// --------------------------------------------------
body{
  font-weight: 300;
}

//
// Typography
// --------------------------------------------------
h2,h3,h4{
  color: darken($brand-primary,5%);
  small{
    font-size: 50%;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 0 0 .7em 0;
  }
  &.first-header{
    margin-top: 0;
  }
}
h2{
  font-size: $font-size-h2*0.6;
  @media(min-width: $screen-xs-min){
    font-size: $font-size-h2*0.8;
  }
  @media(min-width: $screen-sm-min){
    font-size: $font-size-h2;
  }
  @media(min-width: $screen-md-min){
    font-size: $font-size-h2*1.2;
  }
  .tab-content &{
    @media(min-width: $screen-md-min){
      font-size: $font-size-h2;
    }
  }
}
h3{
  font-size: $font-size-h3;
  @media(min-width: $screen-sm-min){
    font-size: $font-size-h3;
  }
  @media(min-width: $screen-md-min){
    font-size: $font-size-h3*1.2;
  }
}
.display-1{
  font-size: $font-size-h1*0.8;
  @media(min-width: $screen-sm-min){
    font-size: $font-size-h1*1.2;
  }
  @media(min-width: $screen-md-min){
    font-size: $font-size-h1*1.3;
  }
}
.list-item-number{
  color: $brand-primary;
  width: 2.5em;
  height: 2.5em;
  display: inline-block;
  border: 2px solid $brand-primary;
  border-radius: 50%;
  text-align: center;
  line-height: 2em;
}

//
// icons
// --------------------------------------------------
.btn-lg{
  .material-icons{
    vertical-align: middle;
  }
}


//
// Buttons
// --------------------------------------------------

// Common styles
.btn-default,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {


  // Reset the shadow
  &:active,
  &.active {
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
  }

  .badge {
    text-shadow: none;
  }
}

// Mixin for generating new styles
@mixin btn-styles($btn-color: #555) {
  background: rgba($btn-color, .8);
  border-color: rgba($btn-color, 1);
  background-repeat: repeat-x;
 // padding: .6em 2em 0.8em;
  transition: all cubic-bezier(0,.79,.48,1) 0.3s;
  &:hover{
    background: rgba($btn-color, 1);
    border-color: rgba($btn-color, 1);
  }
  &:active,
  &.active {
    background: rgba($btn-color, 1);
  }
  &:focus{
    outline: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: darken($btn-color, 12%);
      background-image: none;
    }
  }
}

// Mixin for generating new styles
@mixin btn-outline-styles($btn-color: #555) {
  background: rgba($btn-color, 0);
  border-color: rgba($btn-color, .5);
  color: $btn-color;
  transition: all cubic-bezier(0,.79,.48,1) 0.3s;
  &:hover{
    background: rgba($btn-color, .1);
    border-color: rgba($btn-color, 1);
  }
  &:active,
  &.active {
    background: rgba($btn-color, .2);
  }
  &:focus{
    outline: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: darken($btn-color, 12%);
      background-image: none;
    }
  }
}

// Common styles
.btn {
  // Remove the gradient for the pressed/active state
  &:active,
  &.active {
    box-shadow: none;
    background-image: none;
  }
}

// Apply the mixin to the buttons
.btn-default {
  @include btn-styles(#FFF);
  color: #FFF;
  background: rgba(255,255,255,0.15);
  border: 1px solid rgba(255,255,255,.8);
  &:hover{
    background: rgba(255,255,255,.9);
    border: 1px solid rgba(255,255,255,1);
  }
  &.btn-inverted{
    @include btn-styles($gray-dark);
    background: rgba($gray-dark,0);
    color: rgba($gray-dark,.7);
    border: 1px solid rgba($gray-dark,.3);
    &:hover{
      color: rgba($gray-dark,1);
      border: 1px solid rgba($gray-dark,.5);
      background: rgba($gray-dark,0);
    }
  }
}

.btn-outline-default {
  @include btn-outline-styles(#FFF);
  color: #FFF;
  border: 1px solid;
}


.btn-primary {
  @include btn-styles($btn-primary-bg);
  &:focus{
    border-color: darken($btn-primary-bg,5%);
  }
}
.btn-success { @include btn-styles($btn-success-bg); }
.btn-info    { @include btn-styles($btn-info-bg); }
.btn-warning { @include btn-styles($btn-warning-bg); }
.btn-danger  { @include btn-styles($btn-danger-bg); }


//
// Container
// --------------------------------------------------
.content-section{
  padding: 2em 0 2.5em;
  @media (min-width: $screen-md-min) {
    padding: 6em 0 6.5em;
  }
}
.container,
.container-fluid{
  &.bg-image{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &.bg-key{
      background-image: url(../img/bg-keyvisual.jpg);
      small{
        color: rgba(#FFF,.5);
      }
      .row address a, .row p a{
        color: $brand-primary;
        &:hover{
          color: lighten($brand-primary,5%);
        }
      }
    }
  }
  &.bg-grey{
    background: $gray-lighter;
  }
  &.content-light{
    color: #FFF;
    & h2{
      color: #FFF;
    }
  }
}
.valign{
  @media (min-width: $grid-float-breakpoint){
    display: flex;
    align-items: center;
  }
}



//
// Helper
// --------------------------------------------------
.block-element{
  display: block;
}
.img-responsive{
  display: inline-block;
}
.illustration-primary{
  height: 3em;
  width: auto;
}

//
// Jumbotron
// --------------------------------------------------
.jumbotron{
  padding: 25vh 0 7vh;
  margin: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  @media (min-width: $screen-xs-min){
    text-align: left;
    padding: 25vh 0;
    background-position: center;
  }
  h2{
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
    color: #FFF;
    margin-bottom: 1em;
  }
}


//
// Tabs
// --------------------------------------------------
.nav-pills-bubbles{
  &.nav-justified{
    width: auto;
    margin-left: -($grid-gutter-width / 2);
    margin-right: -($grid-gutter-width / 2);
    li {
      background: lighten($brand-primary, 90%);
      background: $gray-lighter;
      @include transition(all);
      a{
        text-align: left;
        font-weight: 400;
        color: $gray-base;
        background: transparent;
        border-radius: 0;
        padding: 2em 2.5em 2.1em;
      }
      &:hover{
        background: lighten($brand-primary, 80%);
      }
      &:after{
        top: 60%;
        @include transition(all);
      }
      &.active{
        background: darken($brand-primary,8%);
        background: #ff6600;
        @media (min-width: $grid-float-breakpoint){
          &:after{
            top: 100%;
          	left: 80%;
          	border: solid transparent;
          	content: " ";
          	height: 0;
          	width: 0;
          	position: absolute;
          	pointer-events: none;
          	border-color: rgba($brand-primary,0);
          	border-top-color: darken($brand-primary,8%);
          	border-width: 50px;
            border-right-width: 0;
          	margin-left: -50px;
          }
        }
      }
      &.active a{
        color: #FFF;
        background: transparent;
      }
    }
    li.active.bg-green{
      background-color: $brand-primary;
      &:after{
        border-top-color: $brand-primary;
      }
    }
    li.active.bg-orange{
      background-color: #ff6600;
      &:after{
        border-top-color: #ff6600;
      }
    }
    li.active.bg-red{
      background-color: #d1365f;
      &:after{
        border-top-color: #d1365f;
      }
    }
  }
}

//
// Content Slider
// --------------------------------------------------
.content-slider{
  position: relative;
  border: 4px solid rgba($text-color-invert,.3);
  border-radius: 5px;
  .content-slider-wrap{
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .slider-wrapper,
  .flex-viewport{
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  .slider-panel {
    color: #FFF;
    display: block;
    padding: 0;
    margin: 0;
    width: 100%;
    transition: height 0.3s ease 0s;
    img{
      width: 100%;
      height: auto;
      display: block;

    }
  }
  .flex-direction-nav{
    color: #FFF;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    z-index: 100;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    transition: all 0.2s ease 0s;
    visibility: visible;
    a{
      display: block;
      position: absolute;
      text-align: center;
      display: block;
      outline: none;
      width: 2em;
      height: 2em;
      line-height: 2.5em;
      color: $text-color-invert;
      .material-icons{
        font-size: $font-size-base*2;
      }
    }
    .flex-prev{
      left: $grid-gutter-width/2;
      cursor: pointer;
      display: inline-block;
    }
    .flex-next{
      right: $grid-gutter-width/2;
      cursor: pointer;
      display: inline-block;
    }
  }
  /* Slider Nav */
  ol.flex-control-nav{
    margin: 0 auto;
    list-style: none;
    padding: 0;
    position: absolute;
    bottom: -($grid-gutter-width*1.5);
    left: 10%;
    right: 10%;
    z-index: 1000;
    text-align: center;
    li{
      display: inline-block;
      margin: 0 0em;
      a{
        cursor: pointer;
        display: inline-block;
        color: transparent;
        padding: 0.3em 0.5em;
        text-align: center;
        transition: all 0.2s ease 0s;
        &:before{
          content: ' ';
          display: inline-block;
          width: 18px;
          height: 5px;
          background: rgba($text-color-invert,.4);
          transition: all 0.2s ease 0s;
        }
      }
    }
  }
  ol.flex-control-nav li a:hover:before,
  ol.flex-control-nav li a.flex-active:before{
    background: rgba($text-color-invert,1);
  }
}


//
// Login Screen
// --------------------------------------------------
.login-screen{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  &:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#000,.5);
  }
  .login-screen_panel{
    text-align: center;
    position: relative;
    margin: 0 auto;
    width: 90%;
    transform: translatey(-55%);
    top: 50%;
    padding: $grid-gutter-width;
    background: rgba(#000,0.6);
    border-radius: 1em;
    @media (min-width: $screen-xs-min) {
      width: auto;
      max-width: 30em;
    }
    @media (min-width: $screen-lg-min) {
      padding: $grid-gutter-width*1.5;
      max-width: 35em;
    }
  }
  .login-screen_panel_footer{
    margin-top: $grid-gutter-width;
    border-top: 1px solid rgba(#FFF,.2);
    padding-top: $grid-gutter-width;
    font-size: $font-size-small;
    p{
      margin: 0;
    }
  }
}


//
// Hero Slider
// --------------------------------------------------
.hero-slider{
  position: relative;
  display: block;
  background-color: $gray-dark;
  height: 100vh;
  overflow: hidden;
  @media (min-width: $screen-lg-min) {
    height: 80vh;
  }
  // Prevent flashing on load
  & > .hero-slider-wrap > .slider-panel{
    display: none;
    &:first-child{
      display: block;
    }
  }
  .display-1{
    text-shadow: 2px 2px 20px rgba(#000,.5);
    color: lighten($brand-primary,5%);
  }
  .hero-slider-wrap{
    overflow: hidden;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  & .slider-wrapper,
  & .flex-viewport{
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  .slider-panel {
    font-size: $jumbotron-font-size;
    height: 100vh;
    color: #FFF;
    display: block;
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10;
    transition: height 0.3s ease 0s;
    @media (min-width: $screen-lg-min) {
      height: 80vh;
    }
    &:after{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0,0,0,.2);
      z-index: 1;
    }
  }
  .slider-panel-content{
    position: absolute;
    z-index: 10;
    top: 60%;
    transform: translateY(-50%);
    @media (min-width: $screen-lg-min) {
      top: 70%;
      transform: translateY(-50%);
    }
  }
  /* Slider Nav */
  ol.flex-control-nav{
    margin: 0 auto;
    list-style: none;
    padding: 0;
    position: absolute;
    bottom: $grid-gutter-width;
    left: 10%;
    right: 10%;
    z-index: 1000;
    text-align: center;
    li{
      display: inline-block;
      margin: 0 0em;
      a{
        cursor: pointer;
        display: inline-block;
        color: transparent;
        padding: 0.3em 0.5em;
        text-align: center;
        transition: all 0.2s ease 0s;
        &:before{
          content: ' ';
          display: inline-block;
          width: 18px;
          height: 5px;
          background: rgba($text-color-invert,.4);
          transition: all 0.2s ease 0s;
        }
      }
    }
  }
  ol.flex-control-nav li a:hover:before,
  ol.flex-control-nav li a.flex-active:before{
    background: rgba($text-color-invert,1);
  }
}




//
// Images
// --------------------------------------------------

.thumbnail,
.img-thumbnail {
  @include box-shadow(0 1px 2px rgba(0,0,0,.075));
}


//
// Dropdowns
// --------------------------------------------------

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background-color: darken($brand-primary, 5%);
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  background-color: darken($brand-primary, 5%);
}


//
// Navbar
// --------------------------------------------------

// Default navbar
.navbar-default {
  margin-bottom: 0;
  border: none;
  background: rgba($navbar-default-bg,1);
  color: $gray-dark;
  @include transition(all);
  &>.container{
    position: relative;
  }
  .btn-outline-default{
    @include btn-outline-styles($text-color);
  }
  a{
    //background: none !important;
  }
  .navbar-header{
    float: none;
    @media (min-width: $screen-lg-min) {
      float: left;
    }
  }
  .main-navigation{
    @media screen and (min-width: $screen-sm-min) and (max-width: $screen-lg) {
      float: none !important;
    }
  }
  .navbar-form{
    margin-bottom: 0;
    @media (min-width: $grid-float-breakpoint) {
      float: right !important;
      margin-top: 10px;
    }
  }
  .topbar-menu{
    font-size: .8em;
    .dropdown.open{
      a.dropdown-toggle{
        @media (min-width: $grid-float-breakpoint) {
          color: rgba($text-color-invert, 1);
          background: none !important;
        }
      }
    }
    @media (min-width: $grid-float-breakpoint) {
      position: absolute;
      top: -($grid-gutter-width*1.5);
      right: 0;
      a.dropdown-toggle{
        @include transition(all);
        color: rgba($text-color-invert,.5);
        &:hover{
          color: rgba($text-color-invert,1);
        }
      }
    }
  }
  @media (min-width: $grid-float-breakpoint) {
    &.transparent {
      .topbar-menu{
        display: block;
        & > ul > li > a{
          @include transition(all);
          color: rgba($text-color-invert,.4) !important;
          &:hover{
            color: rgba($text-color-invert,1) !important;
          }
        }
      }
      background: rgba($navbar-default-bg, 0);
      .navbar-brand {
        .logo-secondary {
          fill: #FFF;
        }
        .logo-primary {
          fill: #FFF;
        }
      }
      .navbar-nav > li > a {
        color: #FFF;
      }
      .btn-outline-default {
        @include btn-outline-styles($text-color-invert);
      }
      @media (min-width: $screen-md-min) {
        padding: 2.5em 0 0.8em;
      }
    }
  }
  @media (min-width: $screen-md-min) {
    padding: 0.8em 0;
  }
  .navbar-nav{
    margin: .5em 0;
    .active>a, .active>a:hover{
      color: darken($brand-primary,10%);
      background: none;
    }
    .active>a:focus{
      color: darken($brand-primary,10%);
      background: none;
    }
  }
  .navbar-brand{
    width: 200px;
    @media (min-width: $screen-sm-min) {
      width: 250px;
      padding: 7px 15px;
      height: auto;
      margin-top: $grid-gutter-width/2;
    }
    @media (min-width: $screen-md-min) {
      width: 300px;
      padding: 7px 15px;
      height: auto;
      margin-top: 0;
    }
    .logo-secondary{
      fill: #727272;
    }
    .logo-primary{
      fill: darken($brand-primary,5%);
    }
    svg{
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      height: auto;
    }
  }
  .navbar-nav > .open > a,
  .navbar-nav > .active > a {
    background: none;
    color: darken($brand-primary,10%);
  }
}
.navbar-brand,
.navbar-nav > li > a {
  text-transform: uppercase;
  font-weight: 400;
  padding: .5em 1em;
  &:hover{
    color: $brand-primary;
    background-color: transparent;
  }
}

// Hide Topbar in Singlepage-Navigation
.navbar-sp{
  @media (min-width: $grid-float-breakpoint) {
    .topbar-menu {
      display: none;
    }
  }
}

.navbar-sub{
  @media (min-width: $grid-float-breakpoint) {
    padding: 2.5em 0 .8em;
  }
  .topbar-menu{
    @media (min-width: $grid-float-breakpoint) {
      right: $grid-gutter-width/2;
    }
    .navbar-nav > li > a{
      color: rgba(#000,.4) !important;
    }
  }
}


// Inverted navbar
.navbar-inverse {
  @include gradient-vertical($start-color: lighten($navbar-inverse-bg, 10%), $end-color: $navbar-inverse-bg);
  @include reset-filter; // Remove gradient in IE<10 to fix bug where dropdowns don't get triggered; see https://github.com/twbs/bootstrap/issues/10257
  border-radius: $navbar-border-radius;
  .navbar-nav > .open > a,
  .navbar-nav > .active > a {
    @include gradient-vertical($start-color: $navbar-inverse-link-active-bg, $end-color: lighten($navbar-inverse-link-active-bg, 2.5%));
    @include box-shadow(inset 0 3px 9px rgba(0,0,0,.25));
  }
  .navbar-brand,
  .navbar-nav > li > a {
    text-shadow: 0 -1px 0 rgba(0,0,0,.25);
  }
}

// Undo rounded corners in static and fixed navbars
.navbar-static-top,
.navbar-fixed-top,
.navbar-fixed-bottom {
  border-radius: 0;
}

// Fix active state of dropdown items in collapsed mode
@media (max-width: $grid-float-breakpoint-max) {
  .navbar .navbar-nav .open .dropdown-menu > .active > a {
    &,
    &:hover,
    &:focus {
      color: #fff;
      @include gradient-vertical($start-color: $dropdown-link-active-bg, $end-color: darken($dropdown-link-active-bg, 5%));
    }
  }
}


//
// Alerts
// --------------------------------------------------

// Common styles
.alert {
  text-shadow: 0 1px 0 rgba(255,255,255,.2);
  $shadow: inset 0 1px 0 rgba(255,255,255,.25), 0 1px 2px rgba(0,0,0,.05);
  @include box-shadow($shadow);
}

// Mixin for generating new styles
@mixin alert-styles($color) {
  @include gradient-vertical($start-color: $color, $end-color: darken($color, 7.5%));
  border-color: darken($color, 15%);
}

// Apply the mixin to the alerts
.alert-success    { @include alert-styles($alert-success-bg); }
.alert-info       { @include alert-styles($alert-info-bg); }
.alert-warning    { @include alert-styles($alert-warning-bg); }
.alert-danger     { @include alert-styles($alert-danger-bg); }


//
// Progress bars
// --------------------------------------------------

// Give the progress background some depth
.progress {
  @include gradient-vertical($start-color: darken($progress-bg, 4%), $end-color: $progress-bg)
}

// Mixin for generating new styles
@mixin progress-bar-styles($color) {
  @include gradient-vertical($start-color: $color, $end-color: darken($color, 10%));
}

// Apply the mixin to the progress bars
.progress-bar            { @include progress-bar-styles($progress-bar-bg); }
.progress-bar-success    { @include progress-bar-styles($progress-bar-success-bg); }
.progress-bar-info       { @include progress-bar-styles($progress-bar-info-bg); }
.progress-bar-warning    { @include progress-bar-styles($progress-bar-warning-bg); }
.progress-bar-danger     { @include progress-bar-styles($progress-bar-danger-bg); }

// Reset the striped class because our mixins don't do multiple gradients and
// the above custom styles override the new `.progress-bar-striped` in v3.2.0.
.progress-bar-striped {
  @include gradient-striped;
}


//
// List groups
// --------------------------------------------------

.list-group {
  border-radius: $border-radius-base;
  @include box-shadow(0 1px 2px rgba(0,0,0,.075));
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  text-shadow: 0 -1px 0 darken($list-group-active-bg, 10%);
  @include gradient-vertical($start-color: $list-group-active-bg, $end-color: darken($list-group-active-bg, 7.5%));
  border-color: darken($list-group-active-border, 7.5%);

  .badge {
    text-shadow: none;
  }
}


//
// Panels
// --------------------------------------------------

// Common styles
.panel {
  @include box-shadow(0 1px 2px rgba(0,0,0,.05));
}

// Mixin for generating new styles
@mixin panel-heading-styles($color) {
}

// Apply the mixin to the panel headings only
.panel-default > .panel-heading   {
  @include panel-heading-styles($panel-default-heading-bg);
  border: none;
}
.panel-primary > .panel-heading   { @include panel-heading-styles($panel-primary-heading-bg); }
.panel-success > .panel-heading   { @include panel-heading-styles($panel-success-heading-bg); }
.panel-info > .panel-heading      { @include panel-heading-styles($panel-info-heading-bg); }
.panel-warning > .panel-heading   { @include panel-heading-styles($panel-warning-heading-bg); }
.panel-danger > .panel-heading    { @include panel-heading-styles($panel-danger-heading-bg); }


//
// Pricing
// --------------------------------------------------

//
// Register Steps
// --------------------------------------------------
.register-steps{
  margin: 2em 0;
  dd{
    margin-bottom: 1em;
  }
}



//
// Footer
// --------------------------------------------------
.page-footer{
  padding: 2em 0;
  background: $gray-darker;
  color: $gray-light;
}

//
// Wells
// --------------------------------------------------

.well {
  @include gradient-vertical($start-color: darken($well-bg, 5%), $end-color: $well-bg);
  border-color: darken($well-bg, 10%);
  $shadow: inset 0 1px 3px rgba(0,0,0,.05), 0 1px 0 rgba(255,255,255,.1);
  @include box-shadow($shadow);
}

//
// Product Finder
// --------------------------------------------------
.product-finder{
  display: none;
  @media (min-width: $grid-float-breakpoint) {
    display: block;
  }
  .product-finder_chart{
    cursor: pointer;
  }
  .form-group{
    padding-top: $grid-gutter-width/2;
    padding-bottom: $grid-gutter-width;
    border-top: 1px solid lighten($gray-light,40%);
    margin-bottom: 0;
  }
  .product-finder_header{
    margin-bottom: $grid-gutter-width*2;
  }
  .control-label{
    text-align: left;
    font-weight: normal;
  }
  .product-finder_rightwrong{
    margin-bottom: 1rem;
    overflow: hidden;
  }
}
.product-title{
  display: block;
  font-size: 4rem;
  font-weight: bold;
  line-height: 1.1em;
  color: rgba($gray-base,.3);
  margin-bottom: $grid-gutter-width/2;
}
.nav-pills-bubbles{
  li.active{
    .product-title{
      color: rgba($text-color-invert,1);
    }
  }
}
#tab-products{
  display: none;
  @media (min-width: $grid-float-breakpoint) {
    display: block;
  }
}
//
// Range Slider
// --------------------------------------------------


// Base Colors
$shade-10: #2c3e50 !default;
$shade-1: #d7dcdf !default;
$shade-0: #fff !default;
$teal: #1abc9c !default;


// Reset

.range-slider {
  margin: 0 0 0 0%;
  display: inline-block;
  vertical-align: bottom;
}


// Range Slider
$range-width: 100% !default;

$range-handle-color: $brand-primary !default;
$range-handle-color-hover: darken($brand-primary,10%) !default;
$range-handle-size: 20px !default;

$range-track-color: $gray-lighter !default;
$range-track-height: 10px !default;

$range-label-color: $shade-10 !default;
$range-label-width: 60px !default;

.range-slider {
  width: $range-width;
}

.range-slider__range {
  -webkit-appearance: none;
  width: calc(100% - (#{$range-label-width + 13px}));
  height: $range-track-height;
  border-radius: 5px;
  background: $range-track-color;
  outline: none;
  padding: 0;
  margin: 0;

  // Range Handle
  &::-webkit-slider-thumb {
    appearance: none;
    width: $range-handle-size;
    height: $range-handle-size;
    border-radius: 50%;
    background: $range-handle-color;
    cursor: pointer;
    transition: background .15s ease-in-out;

    &:hover {
      background: $range-handle-color-hover;
    }
  }

  &:active::-webkit-slider-thumb {
    background: $range-handle-color-hover;
  }

  &::-moz-range-thumb {
    width: $range-handle-size;
    height: $range-handle-size;
    border: 0;
    border-radius: 50%;
    background: $range-handle-color;
    cursor: pointer;
    transition: background .15s ease-in-out;

    &:hover {
      background: $range-handle-color-hover;
    }
  }

  &:active::-moz-range-thumb {
    background: $range-handle-color-hover;
  }
}


// Range Label
.range-slider__value {
  display: inline-block;
  position: relative;
  width: $range-label-width;
  color: $shade-0;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  background: $range-label-color;
  padding: 5px 10px;
  margin-left: 8px;

  &:after {
    position: absolute;
    top: 8px;
    left: -7px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-right: 7px solid $range-label-color;
    border-bottom: 7px solid transparent;
    content: '';
  }
}


// Firefox Overrides
::-moz-range-track {
  background: $range-track-color;
  border: 0;
}

input::-moz-focus-inner,
input::-moz-focus-outer {
  border: 0;
}

//
// Charts
// --------------------------------------------------

@mixin vertical {
  .charts--vertical {
    height: 50rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-end;
    align-content: space-between;
    position: relative;
    .chart_title{
      position: absolute;
      font-size: 4rem;
      bottom: 2rem;
      left: 3.5rem;
      font-weight: bold;
      line-height: 0;
      transform: rotate(-90deg);
      transform-origin: left bottom;
      color: rgba($text-color-invert,.5);
      @include transition(all);
    }
    .charts__chart {
      position: relative;
      height: 100%;
      margin: 0 2rem;
      @include transition(all);
      > .charts__chart { width: 100%; }
    }
    &.charts--grouped {
      &::after {
        content: "Oops! Seems that you used 'charts--grouped' in 'charts--vertical'. This isn't supported.";
        font-size: 2rem;
        color: #D0716F;
      }
      .charts__chart { display: none; }
    }
  }
}


@mixin vertical-sizing {
  .charts--vertical {
    .chart--xs {
      width: .5em;
      margin-left: 1.5rem;
    }
    .chart--sm {
      width: 1em;
      margin-left: 1.4rem;
    }
    .chart--lg {
      width: 3em;
      .charts__percent {
        left: 0;
      }
    }
    .chart--xl {
      width: 30%;
    }
  }
}

$vertical: true;

$hover-percent: 5%;
$font-size-base: 10px;

@mixin percentage {
  display: block;
  margin-left: 45%;
  position: relative;
  color: lighten(map-get($chart_colors, "default"), 40%);
  font-size: 1.2em;
  top: .21em;
}

// base
.charts {
  font-size: $font-size-base;

  span {
    text-transform: uppercase;
  }
}

// vertical
@if $vertical == true {
  @include vertical;
}

// percentage

.charts__chart {
  background-color: map-get($chart_colors, "default");
  &.chart--hover {
    &:hover {
      background-color: darken(map-get($chart_colors, "default"), $hover-percent);
    }
  }
  width: 100%;
  height: 2em;
  margin: .5em 0;
  > .charts__chart { height: 100%; }

}

// sizing
.chart--xs {
  height : .5em;
}
.chart--sm {
  height : 1em;
}
.chart--lg {
  height : 3em;

  &[data-percent]::after {
    font-size: 1.4em;
    top: .33em;
  }
}
.chart--xl {
  height : 4.5em;

  &[data-percent]::after {
    font-size: 2em;
    top: .4em;
  }
}
@if $vertical == true {
  @include vertical-sizing;
}

// classes of percentage generator
@for $i from 0 through 100 {
  .charts {
    &:not(.charts--vertical) {
      .chart--p#{$i} {
        width: percentage($i / 100);
      }
    }
    @if $vertical == true {
      &.charts--vertical {
        .chart--p#{$i} {
          height: percentage($i / 100);
        }
      }
    }
  }
}
// colors classes generator
@each $name, $color in $chart_colors {
  .chart--#{$name} {
    background-color: #{$color};

    &.chart--hover {
      &:hover {
        background-color: darken($color, $hover-percent);
      }
    } // .chart--hover
  } // .chart--
} //each

.charts--grouped {
  [data-percent]::after {
    display: none;
  }
}


////////////////////////
//      Settings      //
////////////////////////

// overlay
$mfp-overlay-color: #0b0b0b !default; // Color of overlay screen
$mfp-overlay-opacity: 0.8 !default; // Opacity of overlay screen
$mfp-shadow: 0 !default; // Shadow on image or iframe

// spacing
$mfp-popup-padding-left: 8px !default; // Padding from left and from right side
$mfp-popup-padding-left-mobile: 6px !default; // Same as above, but is applied when width of window is less than 800px

$mfp-z-index-base: 1040 !default; // Base z-index of popup

// controls
$mfp-include-arrows: true !default; // Include styles for nav arrows
$mfp-controls-opacity: 0.65 !default; // Opacity of controls
$mfp-controls-color: #FFF !default; // Color of controls
$mfp-controls-border-color: #3F3F3F !default; // Border color of controls
$mfp-inner-close-icon-color: #333 !default; // Color of close button when inside
$mfp-controls-text-color: #CCC !default; // Color of preloader and "1 of X" indicator
$mfp-controls-text-color-hover: #FFF !default; // Hover color of preloader and "1 of X" indicator

// Iframe-type options
$mfp-include-iframe-type: true !default; // Enable Iframe-type popups
$mfp-iframe-padding-top: 40px !default; // Iframe padding top
$mfp-iframe-background: #000 !default; // Background color of iframes
$mfp-iframe-max-width: 900px !default; // Maximum width of iframes
$mfp-iframe-ratio: 9/16 !default; // Ratio of iframe (9/16 = widescreen, 3/4 = standard, etc.)

// Image-type options
$mfp-include-image-type: true !default; // Enable Image-type popups
$mfp-image-background: #444 !default;
$mfp-image-padding-top: 40px !default; // Image padding top
$mfp-image-padding-bottom: 40px !default; // Image padding bottom
$mfp-include-mobile-layout-for-image: true !default; // Removes paddings from top and bottom

// Image caption options
$mfp-caption-title-color: #F3F3F3 !default; // Caption title color
$mfp-caption-subtitle-color: #BDBDBD !default; // Caption subtitle color

// A11y
$mfp-use-visuallyhidden: false !default; // Hide content from browsers, but make it available for screen readers

/* Magnific Popup CSS */

////////////////////////
//
// Contents:
//
// 1. General styles
//    - Transluscent overlay
//    - Containers, wrappers
//    - Cursors
//    - Helper classes
// 2. Appearance
//    - Preloader & text that displays error messages
//    - CSS reset for buttons
//    - Close icon
//    - "1 of X" counter
//    - Navigation (left/right) arrows
//    - Iframe content type styles
//    - Image content type styles
//    - Media query where size of arrows is reduced
//    - IE7 support
//
////////////////////////

////////////////////////
// 1. General styles
////////////////////////

// Transluscent overlay
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $mfp-z-index-base + 2;
  overflow: hidden;
  position: fixed;

  background: $mfp-overlay-color;
  opacity: $mfp-overlay-opacity;
}

// Wrapper for popup
.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $mfp-z-index-base + 3;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; // fixes webkit bug that can cause "false" scrollbar
}

// Root container
.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 $mfp-popup-padding-left;
  box-sizing: border-box;
}

// Vertical centerer helper
.mfp-container {
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

// Remove vertical centering when popup has class `mfp-align-top`
.mfp-align-top {
  .mfp-container {
    &:before {
      display: none;
    }
  }
}

// Popup content holder
.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: $mfp-z-index-base + 5;
}

.mfp-inline-holder,
.mfp-ajax-holder {
  .mfp-content {
    width: 100%;
    cursor: auto;
  }
}

// Cursors
.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur {
  &, .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
  }
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor {
  .mfp-content {
    cursor: auto;
  }
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

// Hide the image during the loading
.mfp-loading {
  &.mfp-figure {
    display: none;
  }
}

// Helper class that hides stuff
@if $mfp-use-visuallyhidden {
  // From HTML5 Boilerplate https://github.com/h5bp/html5-boilerplate/blob/v4.2.0/doc/css.md#visuallyhidden
  .mfp-hide {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
  }
} @else {
  .mfp-hide {
    display: none !important;
  }
}

////////////////////////
// 2. Appearance
////////////////////////

// Preloader and text that displays error messages
.mfp-preloader {
  color: $mfp-controls-text-color;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: $mfp-z-index-base + 4;
  a {
    color: $mfp-controls-text-color;
    &:hover {
      color: $mfp-controls-text-color-hover;
    }
  }
}

// Hide preloader when content successfully loaded
.mfp-s-ready {
  .mfp-preloader {
    display: none;
  }
}

// Hide content when it was not loaded
.mfp-s-error {
  .mfp-content {
    display: none;
  }
}

// CSS-reset for buttons
button {
  &.mfp-close,
  &.mfp-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: $mfp-z-index-base + 6;
    box-shadow: none;
    touch-action: manipulation;
  }
  &::-moz-focus-inner {
    padding: 0;
    border: 0
  }
}

// Close icon
.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;

  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: $mfp-controls-opacity;
  padding: 0 0 18px 10px;
  color: $mfp-controls-color;

  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;

  &:hover,
  &:focus {
    opacity: 1;
  }

  &:active {
    top: 1px;
  }
}

.mfp-close-btn-in {
  .mfp-close {
    color: $mfp-inner-close-icon-color;
  }
}

.mfp-image-holder,
.mfp-iframe-holder {
  .mfp-close {
    color: $mfp-controls-color;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%;
  }
}

// "1 of X" counter
.mfp-counter {
  display: none;
}

// Navigation arrows
@if $mfp-include-arrows {
  .mfp-arrow {
    position: absolute;
    opacity: $mfp-controls-opacity;
    margin: 0;
    top: 50%;
    margin-top: -55px;
    padding: 0;
    width: 90px;
    height: 110px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    &:active {
      margin-top: -54px;
    }
    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  .mfp-arrow-left {
    left: 0;
    color: $text-color-invert;
  }

  .mfp-arrow-right {
    right: 0;
    color: $text-color-invert;
  }
}

// Iframe content type
@if $mfp-include-iframe-type {
  .mfp-iframe-holder {
    padding-top: $mfp-iframe-padding-top;
    padding-bottom: $mfp-iframe-padding-top;
    .mfp-content {
      line-height: 0;
      width: 100%;
      max-width: $mfp-iframe-max-width;
    }
    .mfp-close {
      top: -40px;
    }
  }
  .mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: $mfp-iframe-ratio * 100%;
    iframe {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: $mfp-shadow;
      background: $mfp-iframe-background;
    }
  }
}

// Image content type
@if $mfp-include-image-type {

  /* Main image in popup */
  img {
    &.mfp-img {
      width: auto;
      max-width: 100%;
      height: auto;
      display: block;
      line-height: 0;
      box-sizing: border-box;
      padding: $mfp-image-padding-top 0 $mfp-image-padding-bottom;
      margin: 0 auto;
    }
  }

  /* The shadow behind the image */
  .mfp-figure {
    line-height: 0;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: $mfp-image-padding-top;
      bottom: $mfp-image-padding-bottom;
      display: block;
      right: 0;
      width: auto;
      height: auto;
      z-index: -1;
      box-shadow: $mfp-shadow;
      background: $mfp-image-background;
    }
    small {
      color: $mfp-caption-subtitle-color;
      display: block;
      font-size: 12px;
      line-height: 14px;
    }
    figure {
      margin: 0;
    }
  }
  .mfp-bottom-bar {
    margin-top: -$mfp-image-padding-bottom + 4;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto;
  }
  .mfp-title {
    text-align: left;
    line-height: 18px;
    color: $mfp-caption-title-color;
    word-wrap: break-word;
    padding-right: 36px; // leave some space for counter at right side
  }

  .mfp-image-holder {
    .mfp-content {
      max-width: 100%;
    }
  }

  .mfp-gallery {
    .mfp-image-holder {
      .mfp-figure {
        cursor: pointer;
      }
    }
  }

  @if $mfp-include-mobile-layout-for-image {
    @media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
      /**
       * Remove all paddings around the image on small screen
       */
      .mfp-img-mobile {
        .mfp-image-holder {
          padding-left: 0;
          padding-right: 0;
        }
        img {
          &.mfp-img {
            padding: 0;
          }
        }
        .mfp-figure {
          // The shadow behind the image
          &:after {
            top: 0;
            bottom: 0;
          }
          small {
            display: inline;
            margin-left: 5px;
          }
        }
        .mfp-bottom-bar {
          background: rgba(0, 0, 0, 0.6);
          bottom: 0;
          margin: 0;
          top: auto;
          padding: 3px 5px;
          position: fixed;
          box-sizing: border-box;
          &:empty {
            padding: 0;
          }
        }
        .mfp-counter {
          right: 5px;
          top: 3px;
        }
        .mfp-close {
          top: 0;
          right: 0;
          width: 35px;
          height: 35px;
          line-height: 35px;
          background: rgba(0, 0, 0, 0.6);
          position: fixed;
          text-align: center;
          padding: 0;
        }
      }
    }
  }
}

// Scale navigation arrows and reduce padding from sides
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: $mfp-popup-padding-left-mobile;
    padding-right: $mfp-popup-padding-left-mobile;
  }
}


